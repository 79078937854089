<template>
    <div class="appFieldBox">
        <!-- 标题 -->
        <div class="afTitle">
            <p class="atBig">威有可以做</p>
            <p class="atText">我们对各行业的用户调查和市场分析，确保能针对不同行业开发出最适合消费者的软件。</p>
        </div>
        <!-- logo列表 -->
        <div class="afList">
            <ul>
                <li @mousemove="showMove(1)" @mouseleave="showLea(1)">
                    <div class="afLogo">
                        <img v-show="showIcon === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg" />
                        <img v-show="showIcon === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImgc" />
                    </div>
                    <div class="afName">美容</div>
                </li>
                <li @mousemove="showMove(2)" @mouseleave="showLea(2)">
                    <div class="afLogo">
                        <img v-show="showIcon2 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg2" />
                        <img v-show="showIcon2 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg2c" />
                    </div>
                    <div class="afName">直播</div>
                </li>
                <li @mousemove="showMove(3)" @mouseleave="showLea(3)">
                    <div class="afLogo">
                        <img v-show="showIcon3 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg3" />
                        <img v-show="showIcon3 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg3c" />
                    </div>
                    <div class="afName">家政</div>
                </li>
                <li @mousemove="showMove(4)" @mouseleave="showLea(4)">
                    <div class="afLogo">
                        <img v-show="showIcon4 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg4" />
                        <img v-show="showIcon4 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg4c" />
                    </div>
                    <div class="afName">服装</div>
                </li>
                <li @mousemove="showMove(5)" @mouseleave="showLea(5)">
                    <div class="afLogo">
                        <img v-show="showIcon5 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg5" />
                        <img v-show="showIcon5 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg5c" />
                    </div>
                    <div class="afName">旅游</div>
                </li>
                <li @mousemove="showMove(6)" @mouseleave="showLea(6)">
                    <div class="afLogo">
                        <img v-show="showIcon6 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg6" />
                        <img v-show="showIcon6 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg6c" />
                    </div>
                    <div class="afName">金融</div>
                </li>
                <li @mousemove="showMove(7)" @mouseleave="showLea(7)">
                    <div class="afLogo">
                        <img v-show="showIcon7 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg7" />
                        <img v-show="showIcon7 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg7c" />
                    </div>
                    <div class="afName">跑腿</div>
                </li>
                <li @mousemove="showMove(8)" @mouseleave="showLea(8)">
                    <div class="afLogo">
                        <img v-show="showIcon8 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg8" />
                        <img v-show="showIcon8 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg8c" />
                    </div>
                    <div class="afName">智能硬件</div>
                </li>
                <li @mousemove="showMove(9)" @mouseleave="showLea(9)">
                    <div class="afLogo">
                        <img v-show="showIcon9 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg9" />
                        <img v-show="showIcon9 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg9c" />
                    </div>
                    <div class="afName">体育</div>
                </li>
                <li @mousemove="showMove(10)" @mouseleave="showLea(10)">
                    <div class="afLogo">
                        <img v-show="showIcon10 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg10" />
                        <img v-show="showIcon10 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg10c" />
                    </div>
                    <div class="afName">花坊</div>
                </li>
                <li @mousemove="showMove(11)" @mouseleave="showLea(11)">
                    <div class="afLogo">
                        <img v-show="showIcon11 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg11" />
                        <img v-show="showIcon11 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg11c" />
                    </div>
                    <div class="afName">珠宝</div>
                </li>
                <li @mousemove="showMove(12)" @mouseleave="showLea(12)">
                    <div class="afLogo">
                        <img v-show="showIcon12 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg12" />
                        <img v-show="showIcon12 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg12c" />
                    </div>
                    <div class="afName">电商</div>
                </li>
                <li @mousemove="showMove(13)" @mouseleave="showLea(13)">
                    <div class="afLogo">
                        <img v-show="showIcon13 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg13" />
                        <img v-show="showIcon13 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg13c" />
                    </div>
                    <div class="afName">地产</div>
                </li>
                <li @mousemove="showMove(14)" @mouseleave="showLea(14)">
                    <div class="afLogo">
                        <img v-show="showIcon14 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg14" />
                        <img v-show="showIcon14 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg14c" />
                    </div>
                    <div class="afName">物流</div>
                </li>
                <li @mousemove="showMove(15)" @mouseleave="showLea(15)">
                    <div class="afLogo">
                        <img v-show="showIcon15 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg15" />
                        <img v-show="showIcon15 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg15c" />
                    </div>
                    <div class="afName">餐饮</div>
                </li>
                <li @mousemove="showMove(16)" @mouseleave="showLea(16)">
                    <div class="afLogo">
                        <img v-show="showIcon16 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg16" />
                        <img v-show="showIcon16 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg16c" />
                    </div>
                    <div class="afName">医疗</div>
                </li>
                <li @mousemove="showMove(17)" @mouseleave="showLea(17)">
                    <div class="afLogo">
                        <img v-show="showIcon17 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg17" />
                        <img v-show="showIcon17 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg17c" />
                    </div>
                    <div class="afName">图书</div>
                </li>
                <li @mousemove="showMove(18)" @mouseleave="showLea(18)">
                    <div class="afLogo">
                        <img v-show="showIcon18 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg18" />
                        <img v-show="showIcon18 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg18c" />
                    </div>
                    <div class="afName">教育</div>
                </li>
                <li @mousemove="showMove(19)" @mouseleave="showLea(19)">
                    <div class="afLogo">
                        <img v-show="showIcon19 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg19" />
                        <img v-show="showIcon19 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg19c" />
                    </div>
                    <div class="afName">社交</div>
                </li>
                <li @mousemove="showMove(20)" @mouseleave="showLea(20)">
                    <div class="afLogo">
                        <img v-show="showIcon20 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg20" />
                        <img v-show="showIcon20 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="alImg20c" />
                    </div>
                    <div class="afName">其他</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppField",
    data() {
        return {
            showIcon: 1,
            showIcon2: 1,
            showIcon3: 1,
            showIcon4: 1,
            showIcon5: 1,
            showIcon6: 1,
            showIcon7: 1,
            showIcon8: 1,
            showIcon9: 1,
            showIcon10: 1,
            showIcon11: 1,
            showIcon12: 1,
            showIcon13: 1,
            showIcon14: 1,
            showIcon15: 1,
            showIcon16: 1,
            showIcon17: 1,
            showIcon18: 1,
            showIcon19: 1,
            showIcon20: 1,
        };
    },
    methods: {
        showMove(i) {
            switch (i) {
                case 1:
                    this.showIcon = 2;
                    break;
                case 2:
                    this.showIcon2 = 2;
                    break;
                case 3:
                    this.showIcon3 = 2;
                    break;
                case 4:
                    this.showIcon4 = 2;
                    break;
                case 5:
                    this.showIcon5 = 2;
                    break;
                case 6:
                    this.showIcon6 = 2;
                    break;
                case 7:
                    this.showIcon7 = 2;
                    break;
                case 8:
                    this.showIcon8 = 2;
                    break;
                case 9:
                    this.showIcon9 = 2;
                    break;
                case 10:
                    this.showIcon10 = 2;
                    break;
                case 11:
                    this.showIcon11 = 2;
                    break;
                case 12:
                    this.showIcon12 = 2;
                    break;
                case 13:
                    this.showIcon13 = 2;
                    break;
                case 14:
                    this.showIcon14 = 2;
                    break;
                case 15:
                    this.showIcon15 = 2;
                    break;
                case 16:
                    this.showIcon16 = 2;
                    break;
                case 17:
                    this.showIcon17 = 2;
                    break;
                case 18:
                    this.showIcon18 = 2;
                    break;
                case 19:
                    this.showIcon19 = 2;
                    break;
                case 20:
                    this.showIcon20 = 2;
                    break;
            }
        },
        showLea(i) {
            switch (i) {
                case 1:
                    this.showIcon = 1;
                    break;
                case 2:
                    this.showIcon2 = 1;
                    break;
                case 3:
                    this.showIcon3 = 1;
                    break;
                case 4:
                    this.showIcon4 = 1;
                    break;
                case 5:
                    this.showIcon5 = 1;
                    break;
                case 6:
                    this.showIcon6 = 1;
                    break;
                case 7:
                    this.showIcon7 = 1;
                    break;
                case 8:
                    this.showIcon8 = 1;
                    break;
                case 9:
                    this.showIcon9 = 1;
                    break;
                case 10:
                    this.showIcon10 = 1;
                    break;
                case 11:
                    this.showIcon11 = 1;
                    break;
                case 12:
                    this.showIcon12 = 1;
                    break;
                case 13:
                    this.showIcon13 = 1;
                    break;
                case 14:
                    this.showIcon14 = 1;
                    break;
                case 15:
                    this.showIcon15 = 1;
                    break;
                case 16:
                    this.showIcon16 = 1;
                    break;
                case 17:
                    this.showIcon17 = 1;
                    break;
                case 18:
                    this.showIcon18 = 1;
                    break;
                case 19:
                    this.showIcon19 = 1;
                    break;
                case 20:
                    this.showIcon20 = 1;
                    break;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.appFieldBox {
    width: 99.9%;
    // height: 700px;
    margin: 0 auto;
    // margin-top: 50px;
    overflow: hidden;
    // 标题
    .afTitle {
        width: 70%;
        height: 110px;
        margin: 0 auto;
        margin-top: 30px;
        text-align: center;
        .atBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 36px;
        }
        .atText {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            margin-top: 10px;
        }
    }
    // logo列表
    .afList {
        width: 100%;
        height: 500px;
        margin-top: 10px;
        // margin-top: 30px;
        ul {
            max-width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            li {
                width: 19%;
                height: 110px;
                background-color: #f9f9f9;
                border-radius: 10px;
                transition: 0.5s;
                .afLogo {
                    width: 49px;
                    height: 60px;
                    line-height: 50px;
                    margin: 0 auto;
                    margin-top: 15px;
                    position: relative;
                    overflow: hidden;
                    img {
                        width: 1100px;
                        height: 2000px;
                    }
                    .alImg {
                        position: absolute;
                        top: -470px;
                        left: -408px;
                    }
                    .alImgc {
                        position: absolute;
                        top: -410px;
                        left: -408px;
                    }
                    .alImg2 {
                        position: absolute;
                        top: -470px;
                        left: -464px;
                    }
                    .alImg2c {
                        position: absolute;
                        top: -410px;
                        left: -464px;
                    }
                    .alImg3 {
                        position: absolute;
                        top: -470px;
                        left: -522px;
                    }
                    .alImg3c {
                        position: absolute;
                        top: -411px;
                        left: -523px;
                    }
                    .alImg4 {
                        position: absolute;
                        top: -470px;
                        left: -575px;
                    }
                    .alImg4c {
                        position: absolute;
                        top: -410px;
                        left: -575px;
                    }
                    .alImg5 {
                        position: absolute;
                        top: -470px;
                        left: -623px;
                    }
                    .alImg5c {
                        position: absolute;
                        top: -410px;
                        left: -623px;
                    }
                    .alImg6 {
                        position: absolute;
                        top: -470px;
                        left: -673px;
                    }
                    .alImg6c {
                        position: absolute;
                        top: -410px;
                        left: -673px;
                    }
                    .alImg7 {
                        position: absolute;
                        top: -470px;
                        left: -725px;
                    }
                    .alImg7c {
                        position: absolute;
                        top: -410px;
                        left: -725px;
                    }
                    .alImg8 {
                        width: 1200px;
                        height: 2000px;
                        position: absolute;
                        top: -470px;
                        left: -852px;
                    }
                    .alImg8c {
                        width: 1200px;
                        height: 2000px;
                        position: absolute;
                        top: -410px;
                        left: -852px;
                    }
                    .alImg9 {
                        width: 1200px;
                        height: 2000px;
                        position: absolute;
                        top: -470px;
                        left: -908px;
                    }
                    .alImg9c {
                        width: 1200px;
                        height: 2000px;
                        position: absolute;
                        top: -410px;
                        left: -908px;
                    }
                    .alImg10 {
                        position: absolute;
                        top: -470px;
                        left: -875px;
                    }
                    .alImg10c {
                        position: absolute;
                        top: -410px;
                        left: -875px;
                    }
                    .alImg11 {
                        position: absolute;
                        top: -470px;
                        left: -920px;
                    }
                    .alImg11c {
                        position: absolute;
                        top: -410px;
                        left: -919px;
                    }
                    .alImg12 {
                        position: absolute;
                        top: -470px;
                        left: -963px;
                    }
                    .alImg12c {
                        position: absolute;
                        top: -409px;
                        left: -963px;
                    }
                    .alImg13 {
                        width: 1170px;
                        height: 2000px;
                        position: absolute;
                        top: -470px;
                        left: -1070px;
                    }
                    .alImg13c {
                        width: 1170px;
                        height: 2000px;
                        position: absolute;
                        top: -410px;
                        left: -1070px;
                    }
                    .alImg14 {
                        position: absolute;
                        top: -470px;
                        right: 1px;
                    }
                    .alImg14c {
                        position: absolute;
                        top: -408px;
                        right: 1px;
                    }
                    .alImg15 {
                        position: absolute;
                        top: -580px;
                        left: -1px;
                    }
                    .alImg15c {
                        position: absolute;
                        top: -525px;
                        left: -1px;
                    }
                    .alImg16 {
                        position: absolute;
                        top: -580px;
                        left: -50px;
                    }
                    .alImg16c {
                        position: absolute;
                        top: -525px;
                        left: -50px;
                    }
                    .alImg17 {
                        position: absolute;
                        top: -580px;
                        left: -100px;
                    }
                    .alImg17c {
                        position: absolute;
                        top: -525px;
                        left: -100px;
                    }
                    .alImg18 {
                        position: absolute;
                        top: -640px;
                        left: -2px;
                    }
                    .alImg18c {
                        position: absolute;
                        top: -696px;
                        left: -2px;
                    }
                    .alImg19 {
                        position: absolute;
                        top: -640px;
                        left: -55px;
                    }
                    .alImg19c {
                        position: absolute;
                        top: -695px;
                        left: -55px;
                    }
                    .alImg20 {
                        position: absolute;
                        top: -580px;
                        left: -155px;
                    }
                    .alImg20c {
                        position: absolute;
                        top: -525px;
                        left: -155px;
                    }
                }
                .afName {
                    width: 100px;
                    height: 30px;
                    line-height: 30px;
                    margin: 0 auto;
                    text-align: center;
                }
            }
            li:hover {
                transform: scale(1.05);

                box-shadow: 1px 1px 10px 1px gray;
                color: #7781f1;
            }
        }
    }
}
</style>
